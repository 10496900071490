import { useLenis } from "@studio-freight/react-lenis";
import { useContext } from "react";
import { NestLayer } from "../../Layout";
import { HeaderContext } from "../Header/Header";

const HashLink = ({children, href, ...rest}) => {
    const lenis = useLenis();
    const nested = useContext(NestLayer);
    const context = useContext(HeaderContext);

    const handleClick = (e) => {
        if ((e.target.href.split('/#')[0]+'/')==window.location.href.split('/#')[0]) {
            e.preventDefault();
            if (context) {context.setOpen(false)}
            setTimeout(() => {
                lenis.scrollTo(document.getElementById(href.split('#')[1]));
            }, 50);
        }
    }

    return <a onClick={handleClick} href={'../'.repeat(nested)+href} {...rest}>{children}</a>
}

export default HashLink