import { Link } from "react-router-dom"
import Header from "./components/Header/Header"
import Navigation from "./components/Header/Navigation"
import HashLink from "./components/UI/HashLink"
import { showCookieConsent } from "./components/UI/Cookies"
import logoo from './assets/loga/NehodaNaNecisto.webp'
import GDPR from "./assets/documents/PV0 - GDPR - Podmínky ochrany osobních údajů.pdf";
import Overlay from "./components/Header/Overlay"
import { createContext, useEffect, useState } from "react"
import { useLenis } from "@studio-freight/react-lenis"

export const NestLayer = createContext();
const Layout = ({children, nested=0, ...rest}) => {
    const [logo, setLogo]= useState('');
    const lenis = useLenis();
    const showConsent = () => {
        showCookieConsent();
    }
    useEffect(()=>{
        lenis?.on('scroll', (e) => {
            if (!nested) {
                if (e.actualScroll > (document.getElementById('intro')?.scrollHeight*0.75)) {
                    setLogo('scrolled')
                } else {
                    setLogo('')
                }
            }
        })
    }, [lenis])
    return (
        <NestLayer.Provider value={nested}>
            <Header fixed stopScroll breakpoint={1000}>
            <Link to='/' className={'logo '+logo}><img src={logoo} alt="Nehoda na nečisto logo" /></Link>
                <Navigation moveRight>
                    <HashLink href='#projekt'>Projekt</HashLink>
                    <HashLink href="#events">Události</HashLink>
                    <HashLink href="#sluzby">Služby</HashLink>
                    <HashLink href="#petice">Petice</HashLink>
                    <HashLink href="#contact">Kontakt</HashLink>
                    <HashLink href="#events" className='button'>VYZKOUŠEJ VR</HashLink>
                </Navigation>

                <Overlay width='50vw' justify='space-evenly'>
                    <HashLink href='#projekt'>Projekt</HashLink>
                    <HashLink href="#events">Události</HashLink>
                    <HashLink href="#sluzby">Služby</HashLink>
                    <HashLink href="#petice">Petice</HashLink> 
                    <HashLink href="#contact">Kontakt</HashLink>
                    <HashLink href="#events" className='button'>VYZKOUŠEJ VR</HashLink>
                </Overlay>
            </Header>

            <main {...rest}>
                {children}
            </main>
            <footer>
                <div className="footer">
                    <div className="footer__left">
                        <img src={logoo} alt="Nehoda nanečisto logo" />
                        <p>Zážitek na hraně reality <br />pro <b>bezpečné silnice</b>.</p>
                    </div>
                    <div className='footer__right'>
                        <div>
                            <HashLink href='#projekt'>Projekt</HashLink>
                            <HashLink href="#events">Události</HashLink>
                            <HashLink href="#petice">Petice</HashLink>
                            <HashLink href="#contact">Kontakt</HashLink>
                        </div>
                        <div>
                            <a href={GDPR} target='_blank'  rel="noreferrer">GDPR</a>
                            <span onClick={showConsent}>Cookies</span>
                        </div>
                    </div>
                </div>
                <div className="copyright">Copyright © {new Date().getFullYear()} Platforma VIZE 0</div>
            </footer>
        </NestLayer.Provider>
    )
}

export default Layout
