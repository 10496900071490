import { useLenis } from '@studio-freight/react-lenis';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function HashScroll() {
    const lenis = useLenis();
  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    lenis?.scrollTo(0, {immediate:true})
    if (location.hash&&lenis) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        lenis.scrollTo(document.getElementById(lastHash.current));
        window.history.replaceState(null, null, ' ');
        lastHash.current = '';
      }, 100);
    }
  }, [location, lenis]);

  return null;
}

export default HashScroll;