import { Link } from "react-router-dom";
import Event from "../UI/Event";
import React, { useEffect, useState } from "react"; 
import axios from "axios";
import { APIURL } from "../..";

const Events = () => { 
    const [eventsData, setEventsData] = useState({});

    const getCurrentDate = () => { 
        var now = new Date();
        var currentMonth = now.getMonth()+1;
        if (currentMonth < 10) { currentMonth = '0' + currentMonth; };
        return now.getFullYear()+"-"+currentMonth+"-"+now.getDate()
    }

    const getEvents = async () => {
        await axios
            .post(APIURL + "/events", {
                type: 'events',
                orderAsc: "ASC",
                limit: 3,
                page: 1,
                filter: [{name:"date", action:">", value:getCurrentDate()}]
            })
            .then((response) => { 
                setEventsData(response.data.body);
            });
    };

    useEffect(() => {
        getEvents();
    }, []);
    return (
        <>
            <section className="events" id="events">
                <div className="events__box">
                    <div className="perex">
                        <span>Události</span>
                        <h2>Kde nás najdete</h2>
                        <p>
                            Navštivte náš stánek na níže uvedených akcích a
                            zažijte dopravní nehodu nanečisto.
                        </p>
                    </div>

                    <div className="events__container">
                        {eventsData?.events?.map((event, i) => (
                            <React.Fragment key={i}>
                                <Event event={event} />
                            </React.Fragment>
                        ))}
                    </div>
                    <Link
                        to="/terminy"
                        className="button transBlue"
                        style={{ margin: "auto" }}
                    >
                        další události
                    </Link>
                </div>
            </section>
        </>
    );
};

export default Events;
