import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../App";
import { APIURL } from "../..";
import axios from "axios";  

const Sluzby = () => {
    const [servicesData, setServicesData] = useState({});
    const { setModal } = useContext(Store);

    const getServices = async () => {
        await axios
            .post(APIURL + "/collection/items", {
                type: 'services',
                orderAsc: "DESC",
                limit: 3,
                page: 1,
                filter: []
            })
            .then((response) => {
                setServicesData(response.data.body);
            });
    };

    useEffect(() => {
        getServices();
    }, []);


    return (
        <>
            <section className="sluzby" id="sluzby">
                <div className="sluzby__box">
                    <div className="perex">
                        <span>Služby</span>
                        <h2>VR AŽ K VÁM</h2>
                        <p>
                            Je dopravní bezpečnost důležitá pro vaše studenty či zaměstnance? VR dorazí za vámi. Objednejte si jednorázovou akci dopravní bezpečnosti nebo si stáhněte situace pro vaše VR brýle k dlouhodobému využití.
                        </p>
                    </div>
                    <div className="sluzby__box__cont">
                        {servicesData?.items?.sort((a,b)=>a.order-b.order).map((service, i) => (
                            <React.Fragment key={i}>
                                <div className="service">
                                    <img src={service.intro_photo}></img>
                                    <div className="service__desc">
                                        <h3>{service.name}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: service.desc }}></p>
                                        {(Number(service.button) && Number(service.form)) ?
                                            <button type="button" className="blue" onClick={() => setModal("services")}>
                                                {service.button_text}
                                            </button> :
                                            (Number(service.allowed)) ?
                                                <a className={"button blue"} href={service.toDownload}>
                                                    {service.button_text}
                                                </a> : <a className={"button disabled blue"}>
                                                    {service.button_text}
                                                </a>
                                        }

                                    </div>
                                </div>
                            </React.Fragment>
                        ))}



                    </div>
                </div>
            </section>
        </>
    );
};

export default Sluzby;
