import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay,Navigation } from "swiper/modules"
import 'swiper/css/bundle'; 

const Galerie = () => {
    let slidesNum = window.innerWidth>2000?4:window.innerWidth>1200?3:window.innerWidth>800?2:1;
    return <section className="gallery">
        <Swiper
            modules={[Autoplay, Navigation]}
            autoplay={{delay:5000}}
            loop={true}
            navigation={true}
            slidesPerView={slidesNum}
        >
            <SwiperSlide><img src={"./galerie/1.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/2.jpg"} /></SwiperSlide>   
            <SwiperSlide><img src={"./galerie/5.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/6.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/7.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/8.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/9.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/10.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/11.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/12.jpg"} /></SwiperSlide> 
            <SwiperSlide><img src={"./galerie/13.jpg"} /></SwiperSlide> 
        </Swiper>
    </section>
}

export default Galerie