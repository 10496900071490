import { useContext } from "react";
import { Store } from "../../App";

const Petice = () => {
    const { setModal } = useContext(Store);
    return (
        <>
            <section className="petice" id="petice">
                <div className="petice__box">
                    <div className="perex">
                        <span>Petice</span>
                        <h2>ZATOČTE S AGRESIVITOU <br />NA SILNICI!</h2>
                        <p>
                        Přejete si klidné silnice bez agresivních řidičů? Připravujeme petici podporující zavedení změn v zákoně o možnosti sankcionování agresivity za volantem. Pokud chcete být informováni o jejím spuštění, zanechte nám svůj e-mail.
                        </p>
                    </div>
                    <button type="button" className="blue" onClick={() => setModal("petice")}>
                        Chci být informován
                    </button>
                </div>
            </section>
        </>
    );
};

export default Petice;
