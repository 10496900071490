import { useFormContext } from 'react-hook-form'; 
const Checkbox = ({children, name, className='', required, ...rest}) => {
    const { register, formState: {errors } } = useFormContext();
    const validationRules = {
        required: required ? "Toto pole je povinné" : false,
      };
    return (
        <div className={className+" checkbox "+ (errors[name]?'err':'')}>
            <div className="checkbox__line">
                <input type="checkbox" name={name} id={name} {...register(name, validationRules)} {...rest}  />
                <label htmlFor={name}>{children} {required&& <sup>*</sup>}</label>
            </div>
        </div>
    )
}

export default Checkbox;