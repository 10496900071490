import hero from '../../assets/svg/HERO_POSTAVA_v2.webp'; 

const Intro = (scrollTo) => {
    return (
        <>
            <section className="intro" id='intro'>
                <div className="intro__title">
                    <h1>
                        Zážitek na hraně reality pro 
                        <mark> bezpečné silnice</mark>
                    </h1>
                    <p>Zažij <b>VR dopravní nehodu</b> z pohledu řidiče nebo chodce a otestuj své reakční schopnosti. </p>
                    <button type='button' onClick={() => scrollTo.scrollTo("events")}>Vyzkoušej VR</button>
                </div>
                <div className="intro__hero"> 
                    <img src={hero} alt='slečna zkoušejicí VR brýle' />
                </div>
            </section>

        </>
    );
}

export default Intro
