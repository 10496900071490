import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

//  CookieConsent.showPreferences(); 

const ccObjToArr = (obj) => {
    let arr = [];
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            arr = arr.concat(obj[prop]);
        }
    }
    return arr
}
const ccArrToObj = (arr, prop) => {
    let obj = {};
    arr.forEach(s=>obj[s]=prop);
    return obj;
}

export function showCookieConsent() {
    CookieConsent.showPreferences();
}

export function initCookieConsent() {
    CookieConsent.run({
        guiOptions: {
            consentModal: {
                layout: "box",
                position: "bottom left",
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: "box",
                position: "right",
                equalWeightButtons: true,
                flipButtons: false
            }
        },
        onFirstConsent: ({cookie}) => {
            let granted = ccObjToArr(cookie.services);
            if (granted.length) {
                let obj = ccArrToObj(granted, 'granted');
                window.gtag('consent', 'update', obj);
            }
        },
        onChange: function({changedServices}){
            let accepted = ccObjToArr(CookieConsent.getUserPreferences().acceptedServices);
            if (accepted.length) {
                let acceptedObj = ccArrToObj(accepted, 'granted');
                window.gtag('consent', 'update', acceptedObj);
            }
            let rejected = ccObjToArr(CookieConsent.getUserPreferences().rejectedServices);
            if (rejected.length) {
                let rejectedObj = ccArrToObj(rejected, 'denied');
                window.gtag('consent', 'update', rejectedObj);
            }
        },
        onModalShow:({modalName}) => {
            if (modalName==='preferencesModal') {
                document.getElementsByClassName('pm--box')[0].dataset['lenisPrevent'] = true;
            }
        },
        categories: {
            necessary: {
                enabled: true,
                readOnly: true
            },
            functionality: {
                services: {
                    functionality_storage: {}
                }
            },
            analytics: {
                services: {
                    analytics_storage: {}
                }
            },
            marketing: {
                services: {
                    ad_storage: {},
                    ad_user_data: {}
                }
            },
            personalization: {
                services: {
                    ad_personalization:{},
                    personalization_storage: {}
                }
            }
        },
        language: {
            default: "cs",
            translations: {
                cs: {
                    consentModal: {
                        title: `Používáme cookies!`,
                        description: `Aby web fungoval správně, využíváme nezbytná cookies. Jestli se o nich chcete dozvědět víc nebo si navolit dobrovolná cookies, <button class="cc__link" data-cc="show-preferencesModal">klikněte zde.</button>`,
                        acceptAllBtn: "Přijímám vše",
                        acceptNecessaryBtn: "Odmítám vše",
                        showPreferencesBtn: "Podrobné nastavení"
                    },
                    preferencesModal: {
                        title: "Podrobné nastavení cookies",
                        acceptAllBtn: "Přijímám vše",
                        acceptNecessaryBtn: "Odmítám vše",
                        savePreferencesBtn: "Uložit nastavení",
                        closeIconLabel: "Zavřít",
                        serviceCounterLabel: "",
                        sections: [
                            {
                                title: "Využití cookies",
                                description: "Cookies používáme nejen k zabezpečení základních funkcí webu, ale taky abychom vám poskytli tu nejlepší uživatelskou zkušenost a oslovovali vás vhodnými reklamami. Zde si můžete zvolit jednotlivé kategorie a nastavit, jak máme s vašimi daty pracovat."
                            },
                            {
                                title: "Nezbytná základní cookies <span class=\"pm__badge\">Nelze zakázat</span>",
                                description: "Ty jsou nezbytně nutné pro fungování webu a jeho funkcí, které se rozhodnete využívat. Bez nich by náš web nefungoval, proto tato kategorie nemůže být vypnutá.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Funkční cookies",
                                description: "Tyto cookies nám umožňují zapamatovat si Vaše základní volby a vylepšují uživatelský komfort. Jde například o zapamatování si jazyka či umožnění zůstat trvale přihlášen.",
                                linkedCategory: "functionality"
                            },{
                                title: "Analytické cookies",
                                description: "Analytické soubory cookie měří počet návštěv a shromažďují informace o zdrojích návštěvnosti. Používáme je ke zlepšení fungování našeho webu. Pomáhají nám zjistit, které stránky jsou nejoblíbenější nebo jak se na naší platformě pohybujete. Neudělení souhlasu nemá vliv na fungování stránek.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Marketingové cookies",
                                description: "Marketingové (reklamní) cookies používáme my nebo naši partneři, abychom vám mohli zobrazit vhodné obsahy nebo reklamy, a to jak na našich stránkách, tak na stránkách třetích subjektů. Neudělení souhlasu nemá vliv na fungování stránek.",
                                linkedCategory: "marketing"
                            },
                            {
                                title: "Personalizované cookies",
                                description: "Personalizované cookies používáme, abychom přizpůsobili zobrazování reklam a obsahu, které lépe odpovídají vašim zájmům a potřebám. Neudělení souhlasu nemá vliv na fungování stránek.",
                                linkedCategory: "personalization"
                            },
                            {
                                title: "Více informací",
                                description: "V případě jiných dotazů ohledně využívání cookies nás neváhejte nás <a class=\"cc__link\" href=\"mailto:zdenek@agenturagen.cz\">kontaktovat</a>."
                            }
                        ]
                    }
                }
            }
        }
    })
}