import { ReactLenis } from "@studio-freight/react-lenis";
import { createContext, useEffect, useState } from "react";
import { initCookieConsent } from "./components/UI/Cookies";
import { ToastProvider } from "./components/UI/useToast";
import Home from "./Home";
import EventsPage from "./EventsPage";
import { Route, Routes, Navigate } from "react-router-dom";
import HashScroll from "./components/UI/HashScroll";
import FormModal from "./components/FormModal";

import { HelmetProvider } from 'react-helmet-async';

export const Store = createContext();
const App = () => {
    const [modal, setModal] = useState(null);

    useEffect(() => {
        initCookieConsent();
    });

    return (
        <HelmetProvider>
            <ReactLenis root>

                <ToastProvider>
                    <Store.Provider value={{ setModal }}>
                        <Routes>
                            {  //k nasazení
                                /*<Route exact path='/' element={<Home />} />
                                <Route exact path="/terminy" element={<EventsPage />} />
                                <Route exact path="*" element={<Navigate to='/' />} />*/
                            }
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/terminy" element={<EventsPage />} />
                            <Route exact path="*" element={<Navigate to="/" />} />
                        </Routes>
                        <FormModal
                            state={[modal, setModal]}
                        />
                    </Store.Provider>
                </ToastProvider>
                <HashScroll /> 
            </ReactLenis>
            </HelmetProvider>
    );
};

export default App;
