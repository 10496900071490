// import projekt  from "../../assets/img/projekt_1.png";
import ReactPlayer from "react-player"


const Projekt = ({ buttonClick }) => {
    return (
        <section className="projekt" id='projekt'>
            <div className="projekt__box">
                <div className="perex">
                    <span>Projekt</span>
                    <h2>Proč zažít VR</h2>
                    <p><b>Nehoda nanečisto</b> je putovní VR projekt <b>o dopravní (ne)bezpečnosti.</b> Umožňuje <b>zažít</b> jedinečnou zkušenost <b>dopravní nehody v bezpečném prostředí</b> a
                        zvýšit tak prevenci rizikových situací na silnici. <b>Interaktivní VR situace</b> si mohou vyzkoušet <b>děti i dospělí</b> a to z pozice
                        <b> chodce</b> nebo <b>řidiče</b> (v reálném autě). Reakce v dané situaci rozhodne o tom, zda nehoda proběhne či nikoliv.</p>
                </div>
                <div className="projekt__box__right">
                    {/* <img src={projekt} alt="Holčička s VR brýlemi" /> */}
                    <ReactPlayer url="https://www.youtube.com/watch?v=I_ssAkVoB2M" controls width="70vw" height='40vw' />
                </div>
            </div>
        </section>
    )
}

export default Projekt