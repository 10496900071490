import IG from "../../assets/loga/IG.svg";
import FB from "../../assets/loga/FB.svg";
import contact from "../../assets/svg/KONTAKT_RUKA.webp";

import PV0KOOP from "../../assets/loga/PV0-KOOP_logo.svg";
import AUREL from "../../assets/loga/Aurel_logo.png";
import CKP from "../../assets/loga/CKP_logo.png";
import SKODA from "../../assets/loga/SKODA.png";

const Contact = () => {
    return (
        <>
            <section className="contact" id="contact">
                <div className="contact__box">
                    <div className="contact__box__left">
                        <div className="perex">
                            <span>Kontakt</span>
                            <h2>Zůstaňte s námi <br /> v kontaktu</h2>
                            <p>
                                Pro více informací a zajímavostí o dopravě a dopravní
                                bezpečnosti nás sledujte na našich sociálních sítích.
                            </p>
                        </div>

                        <div className="socials">
                            <a href="https://www.facebook.com/vize0.cz/">
                                <img src={FB} alt="Facebook" />
                                <span>@vize0.cz</span>
                            </a>
                            <a href="https://www.instagram.com/vize0.cz/">
                                <img src={IG} alt="Instagram" />
                                <span>@vize0.cz</span>
                            </a>
                        </div>
                    </div>
                    <div className="contact__box__right">
                        <img src={contact} alt='ruka držící telefon' />
                    </div>
                </div>
            </section>
            <section className="partners"> 
                <a href="https://www.platformavize0.cz/" target="_blank"><img className="pv0" src={PV0KOOP} alt="Logo - Platforma vize nula ve spolupráci s Nadací Kooperativy" /></a>
                <a href="https://aurelcz.eu/" target="_blank"><img src={AUREL} alt="Logo - Aurel" /></a>
                <a href="https://www.fondzabranyskod.cz/" target="_blank"><img src={CKP} alt="Logo - CKP" /></a>
                <a href="https://www.skoda-auto.cz/" target="_blank"><img src={SKODA} alt="Logo - Škoda auto" /></a>
            </section>
        </>
    );
};

export default Contact;
