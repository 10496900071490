import { useContext } from 'react';
import { HeaderContext } from './Header';

const Overlay = ({ children, className, ...rest }) => {
  const {open, setOpen, stopScroll, breakpoint} = useContext(HeaderContext);
  if (breakpoint==='all'||window.innerWidth<=breakpoint) return (
    <>
      {stopScroll&&open&&<div className={'overlayBg '+className}
        style={{
          opacity: open?1:0
        }}
        onClick={()=>setOpen(!open)}
      ></div>}
      <div 
        className={'overlay '+className}
        style={{
          width: rest.width||'auto',
          height: rest.height||'100vh',
          background: rest.bg||'white',
          justifyContent: rest.justify||'center',
          padding: rest.padding||'25px 50px',
          transform: rest.direction==='top'?`translateY(${open ? '0' : '-100%'})`:`translateX(${open ? '0' : '100%'})`
        }}
      >{children}</div>
    </>
  )
};
export default Overlay;