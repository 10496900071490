import { useForm, FormProvider } from 'react-hook-form';
import "./Form.scss";

const Form = ({children, className='', onSubmit}) => {
    const methods = useForm({mode: 'onChange'});
    const {handleSubmit, reset} = methods;

    const onSubmitReset = (data) => {
        onSubmit(data);
        setTimeout(reset, 500);
    }
    

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmitReset)} className={'superform '+ className}>
                {children}
            </form>
        </FormProvider>
    )
}

export default Form;