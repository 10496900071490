import './Modal.scss';
import {CSSTransition} from 'react-transition-group';
import { useRef } from 'react';

const Modal = ({children, state}) => {
    const [modal, setModal] = state;
    const nodeRef = useRef(null);

    return (
        <CSSTransition in={modal} ref={nodeRef} timeout={300} unmountOnExit={true} appear={true} classNames='modal'  >
            <div className="supermodal">
                <div className='supermodal__bg' onClick={()=>setModal(null)}></div>
                <div className="supermodal__box">
                    <button className='closeModal' onClick={()=>setModal(null)}>&times;</button>
                    {children}                
                </div>
            </div>
        </CSSTransition>
    )
}
export default Modal;