import axios from "axios";
import Modal from "./UI/Modal";
import Form from "./Form/Form";
import Input from "./Form/Input";
import Checkbox from "./Form/Checkbox";
import { APIURL } from "..";
import { useToast } from "./UI/useToast";
import { useEffect, useState } from "react";
import Loading from "./UI/Loading";
import GDPR from "../assets/documents/PV0 - GDPR - Podmínky ochrany osobních údajů.pdf";

const FormModal = ({ state }) => {
    const { addToast } = useToast();
    let [modaltype, set] = state;
    const [formData, setFormData] = useState({});

    const handleSubmit = (data) => {
        var dataPrep = formData?.inputs.map((d, i) => ({
            id: d.id,
            value: data[d.name],
        }));
        var dataFinal = {
            name: modaltype,
            data: dataPrep,
        };

        axios
            .post(APIURL + "/newAnswer", dataFinal)
            .then((response) => {
                var message = dataFinal.name == "petice" ? "Děkujeme za váš zájem, detailní informace vám budou zaslány na e-mail, až bude petice spuštěná." : "Děkujeme za váš zájem."; 
                addToast({ type: "success", message: message });
            })
            .catch((err) => {
                addToast({
                    type: "error",
                    message:
                        "Nastala se chyba, kontaktujte nás prosím na admin@agenturagen.cz.",
                });
            });
        set(false);
    };

    const getForm = async () => {
        await axios
            .post(APIURL + "/form", { name: modaltype })
            .then((response) => {
                setFormData(response.data.body);
            });
    };

    useEffect(() => {
        modaltype ? getForm() : setFormData(null)
    }, [modaltype]);

    if (modaltype&&formData) return (
        <Modal state={state}>
            <div className="formModal form">
                {formData ? (
                    <>
                        <div className="perex">
                            <h2>
                                <mark>{formData?.display_name}</mark>
                            </h2>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            {formData?.inputs?.map((input, index) => {
                                if (
                                    input.type == "input" ||
                                    input.type == "textarea"
                                ) {
                                    return (
                                        <>
                                            <Input
                                                id={input.id}
                                                label={input.label}
                                                name={input.name}
                                                placeholder={input.placeholder}
                                                className={
                                                    (input.type == "input" && input.name != "firm" && modaltype!= "petice") &&
                                                    "half"
                                                }
                                                required={
                                                    input.required
                                                        ? true
                                                        : false
                                                }
                                                type={input.input_type}
                                            />
                                        </>
                                    );
                                }
                            })}
                            <Checkbox name="GDPR_form" required>
                                Souhlasím s{" "}
                                <a href={GDPR} target="_blank">
                                    GDPR
                                </a>
                            </Checkbox>
                            <button type="submit" className="blue">
                                {"Odeslat"}
                            </button>
                        </Form>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </Modal>
    );
};

export default FormModal;
