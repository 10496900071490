import React, { useEffect, useState } from 'react';
import Hamburger from './Hamburger';
import "./Header.scss"

import { createContext } from "react";
import { useLenis } from '@studio-freight/react-lenis';

export const HeaderContext = createContext();

const Header = ({ children, className, breakpoint, ...rest }) => {
  const [open, setOpen] = useState(false);
  const stopScroll = rest.stopScroll||false; 
  const lenis = useLenis();
  const handleOpen = () => {
    setOpen(!open);
  }

  useEffect(()=>{
    if (stopScroll&&open) {
      document.body.style.overflowY = "hidden";
      lenis.stop();
    } else if (stopScroll) {
      document.body.style.overflowY = "auto";
      if (lenis) lenis.start();
    }
  }, [open])

//   breakpoint: null - neni, jinak napr 400 - max sirka v pixelech kde se hamburger a overlay zobrazuje a navigace skryva, "all" - vzdy se zobrazuje  
  return (
    <header className={className}
      style={{
        position: rest.fixed?'fixed':'relative'
      }}
    >
      <HeaderContext.Provider value={{open, setOpen, stopScroll, breakpoint}}>
      {children}
      {(breakpoint==='all'||window.innerWidth<=breakpoint)&&<Hamburger open={open} onClick={(handleOpen)} breakpoint={breakpoint} />}
      </HeaderContext.Provider >
    </header>
  )
};

export default Header;