import { useContext } from "react";
import { HeaderContext } from "./Header";

const Navigation = ({ children, className='', ...rest }) => {
  const {breakpoint} = useContext(HeaderContext);
  if (breakpoint==='all'||window.innerWidth>breakpoint) return <div className={'nav '+className}
    style={{
      background: rest.bg||'transparent',
      borderRadius: rest.borderRadius||'0',
      padding: rest.padding||'10px',
      gap: rest.gap||'10px',
      marginLeft: rest.moveRight?'auto':'0',
      display: "flex"
    }}
  >{children}</div>
};
export default Navigation;