import { useState } from 'react';
import { useFormContext } from 'react-hook-form'; 
import {Eye, EyeSlash} from '@phosphor-icons/react'

const Input = ({ label, name, type = 'text', required, pattern, placeholder, className='', ...rest }) => {
  const { register, formState: {errors } } = useFormContext();

  // validation
  let autopattern;
  if (!pattern){
    if (type==='email') autopattern = /[^@\s]+@[^@\s]+\.[^@\s]+/;
    if (type==='tel') autopattern = /^[+-]?[\s\d]+$/;
    if (type==='number') autopattern = /^[0-9]*$/;
  }
  const validationRules = {
    required: required ? "Toto pole je povinné" : false,
    pattern: pattern||autopattern ? { value: pattern||autopattern, message: "Neplatný formát" } : undefined
  };
  // 

  // password
  const [showPass, setShowPass] = useState(type==='password'?'password':'text');
  // 

  return (
    <label className={className}>
        <p>{label} {required&&<sup>*</sup>}</p>
        <div className='superinput'>
          {type==='textarea'?
          <textarea 
            name={name} 
            placeholder={placeholder||label}
            className={errors[name]&&'err'} 
            {...register(name, validationRules)} 
            {...rest} ></textarea>:
          (<input
              name={name}
              type={showPass}
              placeholder={placeholder||label}
              {...register(name, validationRules)}
              {...rest} 
              className={errors[name]&&'err'}
          />
          )}
          {type==='password'&&showPass==='password'&&<Eye className='eye' onClick={()=>setShowPass('text')} />}
          {type==='password'&&showPass==='text'&&<EyeSlash className='eye' onClick={()=>setShowPass('password')} />}
        </div>
        {errors[name] && <p className='err'>{errors[name]?.message}</p>}
    </label>
  );
};

export default Input;